window.flo_friends_section = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo_friends_section";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  $('.flo-friends-section__slider-box').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.flo-friends-section__elem-title'
  });
  $('.flo-friends-section__elem-title').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.flo-friends-section__slider-box',
    dots: true,
    centerMode: true,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true
  });
  $('.flo-friends-section__images-box').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    variableWidth: true
  });

  $('.flo-friends-section__elem-title .name-title span').on('click', function(){
    var dataUrl = $(this).data('url');

    $(this).parents('.flo-friends-section__left-side').find('.flo-friends-section__button a').attr('href', dataUrl);
  });
}
