window.flo_stories_category = function(el){
  "use strict";
  var $el = $(el);
  var b = "";
  var dotb = ".flo-stories-category" + b;
  var parent = $el.parents(".flo-block");

  $('.flo-stories-category__category-images .images').slick({
    arrows : false,
    fade : true
  });
  $('.flo-stories-category__category-slider ul li a').hover(function(){
    $('.flo-stories-category__category-slider ul li').removeClass('active');
    $(this).parent().addClass('active');

    var slidego = $(this).data('slide');
    $('.flo-stories-category__category-images .images').slick('slickGoTo', slidego);
  });
}
