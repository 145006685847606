window.flo_image_header_1 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-image-header-1";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  $el.find(dotb + "__right-label").click(function(){
    $("html, body").animate({ scrollTop: window.innerHeight }, 1000);
  });

  $('.flo-image-header-1__full-header-slider').slick({
    arrows : true,
    fade : true
  });

  $('.flo-image-header-1__header-prev-arrow').on('click', function(){
    $('.flo-image-header-1__full-header-slider .slick-next').click();
  });
  $('.flo-image-header-1__header-next-arrow').on('click', function(){
    $('.flo-image-header-1__full-header-slider .slick-prev').click();
  });
}
