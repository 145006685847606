$(function(){
  $(".flo-header").sticky({
    topSpacing: 0,
    zIndex: 8888
  });

  $('.flo-header__open-menu').on('click', function(){
    $('.flo-header__mob, .sticky-wrapper').toggleClass('show');

    $(this).toggleClass('active');

    if($(this).hasClass('active')){
      $(this).html('Close');
    }else{
      $(this).html('Menu');
    }
  });
});
