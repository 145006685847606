window.flo_listing__layout__type_d = function(el){
  "use strict";
  var $el = $(el);
  var $b = ".flo-listing";
  var $img = $el.find($b + "__featured-image");

  function setImgHeight() {
    $img.height($img.width());
  }

  if (window.innerWidth >= 768) {
    setImgHeight();
    $(window).on("resize", function(){
      setImgHeight();
    });
  }
}
