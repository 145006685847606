window.flo_image_header_2 = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-image-header-2";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  $el.find(dotb + "__arrow-icon i").click(function(){
    $("html, body").animate({ scrollTop: window.innerHeight }, 1000);
  });
}
