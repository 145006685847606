window.flo_latest_stories = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-latest-stories";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  // $el.find(dotb + "__stories-posts").masonry({
  //   columnWidth: 1
  // });
  if ($(window).width() > 420) {

    console.log('ok');
    $el.find(dotb + "__stories-posts img").waitForImages({
        finished: function() {
          setTimeout(function(){
            $el.find(dotb + "__stories-posts").masonry({
              columnWidth: '.flo-latest-stories__single-story',
              gutter: '.gutter-sizer',
              itemSelector: '.flo-latest-stories__single-story',
              percentPosition: true,
            });
            $el.find(dotb + "__stories-posts").addClass('show');
          }, 100);
        },
        waitForAll: true
    });
  }else{
    $el.find(dotb + "__stories-posts").addClass('show');
  }
}
