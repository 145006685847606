window.flo_featured_stories = function(el){
  "use strict";
  var $el = $(el);
  var b = "flo-featured-stories";
  var dotb = "." + b;
  var parent = $el.parents(".flo-block");

  $el.find(dotb + "__stories-section").slick({
    fade: true
  });

  $('.flo-featured-stories__arrow-next').on('click', function(){
    $('.flo-featured-stories .slick-next').click();
  });

  $('.flo-featured-stories__arrow-prev').on('click', function(){
    $('.flo-featured-stories .slick-prev').click();
  });
}
