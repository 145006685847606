if ($(window).width() > 420) {
  $('.flo-latest-stories__single-story img').waitForImages({
      finished: function() {
        setTimeout(function(){
          $('.flo-listing__layout--masonry .flo-listing__columns').masonry({
            columnWidth: '.flo-listing__item--mason',
            gutter: '.gutter-sizer',
            itemSelector: '.flo-listing__item--mason',
            percentPosition: true
          });
        }, 100);
      },
      waitForAll: true
  });
}
