window.flo_testimonials = function(el){
  "use strict";
  var $el = $(el);
  var b = "";
  var dotb = ".flo-testimonials" + b;
  var parent = $el.parents(".flo-block");

  $('.flo-testimonials__testimonial-slider').slick({
    arrows : true,
    fade : true,
    adaptiveHeight: false
  });

  $('.flo-testimonials__arrow-next').on('click', function(){
    $('.flo-testimonials .slick-next').click();
  });
  $('.flo-testimonials__arrow-prev').on('click', function(){
    $('.flo-testimonials .slick-prev').click();
  });
}
